<template>
  <div class="config-item">
    <div class="info">
      <div class="label">{{ data.title }}</div>
      <img v-if="selectedIcon" :src="selectedIcon" alt="">
      <div v-else class="value">{{ selectedLabel }} {{ data.unit ? data.unit : '' }}</div>
    </div>
    <div class="tip">
      <TooltipElement v-if="showTooltip" :content="data.tooltip" alwaysShow placement="bottom">
        <img src="@/assets/icons/Info_24.svg" alt="">
      </TooltipElement>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigItem',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    data: Object,
    value: {
      type: [ Number, String ],
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    selectedLabel() {
      if (this.data.options)
        return this.data.options.find(item => item.value === this.value).label
      else 
        return this.data.label ? this.data.label[this.value] : this.value
    },
    selectedIcon() {
      if (this.data.options){
        const selectedOption = this.data.options.find(item => item.value === this.value)
        if(selectedOption.icon === undefined)
          return null
        return require(`@/assets/icons/${selectedOption.icon}`)
      }
      else return null
    }
  },
}
</script>

<style lang="scss" scoped>
.config-item {
  display: flex;
  align-items: center;
  column-gap: 12px;
  .info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .tip {
    width: 24px;
    height: 24px;
  }
  .label {
    font-size: px2rem(24);
    color: #ffffff;
  }
  .value {
    font-size: px2rem(20);
    color: #ffffff;
  }
  .unit {
    color: #ffffff80;
  }
}
</style>